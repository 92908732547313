<template>
  <div>
    <div v-if="!isBusy">
      <b-card bg-variant="">
        <div class="d-flex flex-column flex-md-row">
          <b-avatar
            class="my-auto ml-5"
            size="13em"
            :src="employee.image_url"
            variant="light"
          ></b-avatar>
          <div class="px-">
            <button
              class="btn btn-icon btn-light btn-hover-primary btn-lg mr-3"
              v-on:click="openImageModal"
              v-b-tooltip.hover
              title="Upload Image"
            >
              <span class="svg-icon svg-icon-lg svg-icon-primary menu-icon">
                <inline-svg src="/media/svg/icons/Communication/Write.svg" />
              </span>
            </button>
          </div>
          <div
            class="d-flex flex-column px-md-5 ml-md-10 mt-5 mt-md-0 flex-grow-1"
          >
            <h3 class="font-weight-bolder font-size-h2 mb-1">
              <a href="#" class="text-dark-75">{{ employee.name }}</a>
            </h3>
            <div class="d-flex mb-3 mt-5">
              <span class="text-dark-50 flex-root font-weight-bold">{{
                $t("USERGENERAL.MESSAGE.UNIQUE")
              }}</span>
              <span class="text-dark flex-root font-weight-bold">{{
                employee.code
              }}</span>
            </div>
            <div class="d-flex mb-3">
              <span class="text-dark-50 flex-root font-weight-bold">{{
                $t("USERGENERAL.MESSAGE.EMAIL")
              }}</span>
              <span class="text-dark flex-root font-weight-bold">{{
                employee.email
              }}</span>
            </div>
            <div class="d-flex mb-3">
              <span class="text-dark-50 flex-root font-weight-bold">{{
                $t("USERGENERAL.MESSAGE.JOIN")
              }}</span>
              <span class="text-dark flex-root font-weight-bold">{{
                formatDate(employee.general_details.created_at)
              }}</span>
            </div>
          </div>
        </div>
      </b-card>
    </div>
    <b-skeleton-wrapper v-if="isBusy">
      <b-card>
        <div class="mb-5">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </div>

        <div class="mb-5">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </div>
      </b-card>
    </b-skeleton-wrapper>
    <PersonalProfileDetailsModal
      title="Upload Image"
      :employee="employee"
      @detailsUpdated="fetch"
    ></PersonalProfileDetailsModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import PersonalProfileDetailsModal from "@/modules/personal/components/profile/PersonalProfileDetailsModal";

export default {
  name: "PersonalProfilePage",
  components: { PersonalProfileDetailsModal },
  data() {
    return {
      isBusy: false,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    /**
     * Fetch school employee profile.
     *
     * @returns {Promise<boolean>}
     */
    fetch() {
      this.isBusy = true;
      return this.$store
        .dispatch("getEmployeePersonalProfile")
        .then((response) => {
          console.log("Received", response);
          // this.items = response.data.data;
          return response.data.data;
        })
        .catch(() => {
          this.$bvModal.show("Alamak!");
        })
        .finally(() => (this.isBusy = false));
    },

    /**
     * Format date to DD/MM/YY
     *
     * @param date
     */
    formatDate(date) {
      return dayjs(date).format("DD/MM/YY");
    },

    /**
     *
     */
    openImageModal() {
      this.$bvModal.show("common-employee-details-modal");
    },
  },
  computed: {
    ...mapGetters({ employee: "getEmployeePersonalProfile" }),
  },
};
</script>

<style scoped></style>
