<template>
  <CommonEmployeeDetailsModal :title="title">
    <div class="d-flex justify-content-center">
      <b-avatar
        :src="avatarUrl"
        variant="light"
        size="15em"
        class="mx-auto mb-5"
      ></b-avatar>
    </div>

    <CommonSchoolImageUpload
      @uploadClicked="handleUpload"
      :phase="phase"
      @cancel-clicked="changePhase"
      @webcam-clicked="changePhase"
    ></CommonSchoolImageUpload>
  </CommonEmployeeDetailsModal>
</template>

<script>
import CommonEmployeeDetailsModal from "@/modules/school/components/shared/employee/modal/CommonEmployeeDetailsModal";
import CommonSchoolImageUpload from "@/modules/school/components/shared/utility/CommonSchoolImageUpload";

export default {
  name: "PersonalProfileDetailsModal",
  components: {
    CommonSchoolImageUpload,
    CommonEmployeeDetailsModal,
  },
  props: {
    title: {
      String,
    },
    employee: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      phase: "default",
    };
  },
  methods: {
    changePhase() {
      this.phase = this.phase === "WEBCAM_PHASE" ? "default" : "WEBCAM_PHASE";
    },
    /**
     *
     * @param image
     */
    handleUpload(image) {
      console.log("Personal image: ", image);

      this.$store
        .dispatch("uploadPersonalImage", {
          image: image,
        })
        .then((res) => {
          console.log(res.data);
          this.$bvModal.msgBoxOk(
            "Successfully uploaded image. Image can now be used for facial device recognition.",
            {
              title: "Image uploaded",
              centered: true,
            }
          );
          this.employee.image_url = res.data.url;
        })
        .finally(() => {
          this.phase = "default";
          this.$emit("detailsUpdated");
        });
    },
  },
  computed: {
    avatarUrl() {
      return this.employee.image_url;
    },
  },
};
</script>

<style scoped></style>
